<!-- 图表 -->
<template>
    <div>
        <div id="highchartsT" style="height: 100%;"></div>
    </div>
</template>

<script>
var Highcharts = require('highcharts');
require('highcharts/modules/exporting')(Highcharts);
import draggable from 'highcharts/modules/draggable-points.js'
import { chartsObjket } from "../../plugins/setCharts";
// 注册拖拽事件
draggable(Highcharts);
export default {
    props:{
        issetData:{
            type:Array,
            default:[]
        },
        seriesData:{//添加数据
            type:Object,
            default:{}
        },
        upSeriesData:{//更新数据
            type:String,
            default:''
        },
        delSeriesData:{//删除数据
            type:String,
            default:''
        },
        grainDelect:{//彻底删除
            type:Boolean,
            default:false
        },
        
    },
    watch:{
        seriesData:{
            handler(data){
                this.chart.addSeries(data);//添加
                this.seriesArray.push(this.issetData)
                this.evaluation()//计算和、平均值
                this.setplotLines()//上下限
            },
            deep:true,
        },
        delSeriesData(data){
           // 找到要删除的系列（在这里是 'Series 2'）的索引  
            var seriesIndex = this.chart.series.findIndex(function(series) {  
                return series.name === data;  
            }); 
            var seriesToRemove = this.chart.series[seriesIndex];
            if (seriesIndex !== -1) {  
                this.seriesArray.splice(seriesIndex,1)
                seriesToRemove.remove();  
                this.$emit('update:delSeriesData','')
                this.evaluation()//计算和、平均值
                this.setplotLines()//上下限
            }

        },
        grainDelect(data){//彻底删除
            if(data){
                this.chart.subtitle.textStr = ""//删除副标题
                this.chart.setTitle({text: ''});  //删除主标题
                this.seriesArray = []
                // 销毁图表实例  
                this.chart.destroy();    
                this.$emit('update:grainDelect',false)
                this.getChart()
            }
        },
        upSeriesData(data){
            let seriesIndex
            this.chart.series.forEach(function(series, index) {  
                if (series.name === data) {  
                    seriesIndex = index;  
                }  
            }); 
            this.chart.series[seriesIndex].update({  
                data: [  
                    ['2024-11-01 01:00:00', 7],  
                    ['2024-11-01 02:00:00', 9],
                    ['2024-11-01 03:00:00', 1],  
                    ['2024-11-01 04:00:00', 1],
                ]  
            }, true); // 第二个参数为 true 时，会立即触发重绘
            this.$emit('update:upSeriesData','')
        }
    },
    data() {
        return {
            seriesArray:[],//存储原始数据
            chart:null,
            initPomers:{
                maximumvalue:0,//最大值
                minimumvalue:0,//最小值
                meanvalue:0,//平均值
            }
        }
    },
    created() {
        Highcharts.setOptions({
            lang: {
                resetZoom: '重置缩放比例',
                resetZoomTitle: '重置缩放比例'
            }
        });
    },
    mounted() {
        this.getChart()
    },
    methods:{
        updateSubtitle() { 
            this.chart.subtitle.textStr = `最高温度:${this.initPomers.maximumvalue}℃ 最低温度:${this.initPomers.minimumvalue}℃ 平均温度:${this.initPomers.meanvalue}℃`
            this.chart.setTitle({text: '温度曲线图'});  
        },
        setplotLines() {// 设置新的 plotLines  上下限
            let plotMap = []
            let maxMap = []//筛选出上限map
            let minMap = []//筛选出下限map
            this.seriesArray.forEach((item,index)=>{
                maxMap.push(item[item.length-1].T_tu)
                minMap.push(item[item.length-1].T_tl)
                maxMap = [...new Set(maxMap)]
	            minMap = [...new Set(minMap)]
            })
            maxMap.forEach(item=>{
                plotMap.push({
                    color: 'red',//线的颜色，定义为红色
                    dashStyle: 'LongDashDotDot',//默认值，这里定义为实线
                    value: item,//定义在那个值上显示标示线，这里是在x轴上刻度为3的值处垂直化一条线
                    width: 1,//标示线的宽度，2px
                    id: "",                  //标示线的id，在删除该标示线的时候需要该id标示
                    label: {
                        text: `温度上限（${item}°C）`,//标签的内容
                        align: 'right',//标签的水平位置，水平居左,默认是水平居中center
                        style: {
                            color: '#5fc5ff' // 设置标签文本的字体颜色为蓝色  
                        }
                    }
                })
            })

            minMap.forEach(item=>{
                plotMap.push({
                    color: 'red',//线的颜色，定义为红色
                    dashStyle: 'LongDashDotDot',//默认值，这里定义为实线
                    value: item,//定义在那个值上显示标示线，这里是在x轴上刻度为3的值处垂直化一条线
                    width: 1,//标示线的宽度，2px
                    id: "",                  //标示线的id，在删除该标示线的时候需要该id标示
                    label: {
                        text: `温度下限（${item}°C）`,//标签的内容
                        align: 'right',//标签的水平位置，水平居左,默认是水平居中center
                        style: {
                            color: '#5fc5ff' // 设置标签文本的字体颜色为蓝色  
                        }
                    }
                })
            })
            this.chart.yAxis[0].update({
                plotLines:[...plotMap]
            },true);
            if(this.chart.series.length!=0){//设置可视区域最大最小值
                let mapArr = []
                this.chart.series.forEach(item=>{
                    mapArr = [...mapArr,...item.yData]
                })
                let maxNum = Math.max(...mapArr)>Math.min(...maxMap)?Math.max(...mapArr):Math.min(...maxMap)
                let minNum = Math.min(...mapArr)>Math.min(...minMap)?Math.min(...minMap):Math.min(...mapArr)
                this.chart.yAxis[0].update({  
                    max : maxNum+2,
                    min : minNum-2
                });
            }
            // setTimeout(() => {
            //         this.chart.yAxis[0].removePlotLine('plot-line-2') //把id为plot-line-1的标示线删除
            //         console.log('删除18.5')
            //     }, 3000);
        },
        setpoitLing(plotLinesTop,plotLinesTopName,plotLinesBottom,plotLinesBottomName){
            console.log('kaishi ',plotLinesTop,plotLinesTopName,plotLinesBottom,plotLinesBottomName)

            this.chart.yAxis[0].update({
                plotLines:[{
                        color: 'red',//线的颜色，定义为红色
                        dashStyle: 'LongDashDotDot',//默认值，这里定义为实线
                        value: plotLinesTop,//定义在那个值上显示标示线，这里是在x轴上刻度为3的值处垂直化一条线
                        width: 1,//标示线的宽度，2px
                        id: plotLinesTopName,                  //标示线的id，在删除该标示线的时候需要该id标示
                        label: {
                            text: `温度上限（${plotLinesTop}°C）`,//标签的内容
                            align: 'right',//标签的水平位置，水平居左,默认是水平居中center
                            style: {
                                color: '#5fc5ff' // 设置标签文本的字体颜色为蓝色  
                            }
                        }
                    },{
                        color: 'red',//线的颜色，定义为红色
                        dashStyle: 'LongDashDotDot',//默认值，这里定义为实线
                        value: plotLinesBottom,//定义在那个值上显示标示线，这里是在x轴上刻度为3的值处垂直化一条线
                        width: 1,//标示线的宽度，2px
                        id: plotLinesBottomName,                  //标示线的id，在删除该标示线的时候需要该id标示
                        label: {
                            text: `温度下限（${plotLinesBottom}°C）`,//标签的内容
                            align: 'right',//标签的水平位置，水平居左,默认是水平居中center
                            style: {
                                color: '#5fc5ff' // 设置标签文本的字体颜色为蓝色  
                            }
                        }
                    }]
            },true);
        },
        evaluation(){//求值
            let arr = [...this.chart.series]
            if(arr.length==0){
                this.chart.subtitle.textStr = ""
                this.chart.setTitle({text: ''}); 
                return
            }
            let arrMap = []
            arr.forEach(item=>{
                arrMap = [...arrMap,...item.yData]
            })
            let sum = arrMap.reduce((n,m) => n + m);//求和
			this.initPomers.maximumvalue = Math.max(...arrMap).toFixed(1) //最大值
			this.initPomers.minimumvalue = Math.min(...arrMap).toFixed(1) //最小值
			this.initPomers.meanvalue =  parseFloat(sum / arrMap.length).toFixed(1)//平均值
            this.updateSubtitle()//更新标题
        },
        getChart() {
            this.chart = Highcharts.chart('highchartsT', {
                ...chartsObjket,
            });
        },
    }
}
</script>
<style lang="scss">
</style>